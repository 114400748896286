/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DehazeIcon from '@mui/icons-material/Dehaze';
import { LinksMenu } from './Util';
import { removeUserAuthenticate, isUserAuthenticate, setSessionExpired, isPasswordDefault, isAdmin} from '../api/services/AuthService';
import jwt from 'jsonwebtoken'
import { ThemeProvider } from '@mui/material/styles';
import LogoutIcon from '@mui/icons-material/Logout';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';

import theme from '../theme/Theme';

import { Link, useNavigate } from 'react-router-dom'

import styled from "styled-components";

const Airlines = styled(FlightTakeoffIcon)`
  font-size:18px;
`

const LinkMenu = styled(Link)`
    margin: 0;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    display: block;
    color: inherit;
    text-decoration:none;
`

const Draw = styled.div`
    background-color:#4F001E;
    top: 0;
    width: 100%;
    z-index: 10;
    box-shadow: 0 -9px 20px 9px #000;
    height: 80px;
    position:fixed;
`;

const DivButtonDraw = styled.div`
    z-index: 1,
`;

const Logo = styled.h2`
    color: white;
    float:left;
    position:absolute;
    font-size:40px;
    font-weight: bold;
    font-family: Sans-serif;
    top:20px;
    text-align: center;
    width:100%;
`;

const Saudacao = styled.h5`
    color: white;
    font-weight: bold;
    font-size:12px;
    font-style: italic;
    position:absolute;
    right:50px;
    top:60px;
`;


const BtnMenu = styled.button`
    margin-top: 20px;
    margin-left:10px;
    left:20px;
    background-color:#4F001E;
    color:#FFFF;
    border-color:#FFFF;
    position:relative;
    z-index:1;
`

export default function TemporaryDrawer() {
    const name = 'left';
    const [salutation, setSalutation] = useState('');
    let navigate = useNavigate();

    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const logout = () => {
        setSessionExpired();
        removeUserAuthenticate();
    }

    useEffect(() => {
        const jsonToken = isUserAuthenticate();
        if (jsonToken) {
            const objToken = jwt.decode(jsonToken.token);
            setSalutation(objToken.name);
        } else {
            navigate('/login', {})
        }
    }, [navigate, salutation]);

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <ThemeProvider theme={theme}>
            <Box
                sx={{ width: 250 }}
                role="presentation"
                onClick={toggleDrawer(anchor, false)}
                onKeyDown={toggleDrawer(anchor, false)}
            >
                {
                    isPasswordDefault() === 'false' ? 
                    <>
                        <List>
                            {
                            LinksMenu.map((menu, index) => (
                                !(isAdmin() && menu.label === 'Dashboard') ? 
                                <LinkMenu to={menu.url} key={index}>
                                    <ListItem key={menu.label}>
                                        <ListItemIcon >
                                            {menu.icon}
                                        </ListItemIcon>
                                        <ListItemText sx={{color:"#4F001E"}} primary={menu.label} />
                                    </ListItem>
                                </LinkMenu>
                                :null
                            ))}
                        </List><Divider/>
                    </>: 
                null
                }
                <List>
                    {['Logout'].map((text, index) => (
                        <LinkMenu to='/login' onClick={logout} key={index} state={{ expired: "value" }}>
                            <ListItem key={text}>
                                <ListItemIcon >
                                    {<LogoutIcon sx={{color:"#4F001E"}}/>}
                                </ListItemIcon>
                                <ListItemText sx={{color:"#4F001E"}} primary={text}/>
                            </ListItem>
                        </LinkMenu>
                    ))}
                </List>
            </Box>
        </ThemeProvider>
    );

    return (
        <ThemeProvider theme={theme}>
            <Draw className="draw-main">
                <DivButtonDraw>
                    <React.Fragment key={name}>
                        <BtnMenu onClick={toggleDrawer(name, true)}><DehazeIcon sx={{ fontSize: 40 }} color="secundary" /></BtnMenu>
                        <Drawer
                            anchor={name}
                            open={state[name]}
                            onClose={toggleDrawer(name, false)}>
                            {list(name)}
                        </Drawer>
                    </React.Fragment>
                </DivButtonDraw>
                <Logo>
                    <Airlines /> PORTAL DO AGENTE
                </Logo>
                <Saudacao>
                    Bem vindo(a) {salutation}
                </Saudacao>
            </Draw>
        </ThemeProvider>
    );
}