import RequestPageIcon from '@mui/icons-material/RequestPage';
import CurrencyExchangeTwoToneIcon from '@mui/icons-material/CurrencyExchangeTwoTone';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LockResetIcon from '@mui/icons-material/LockReset';

const LinksMenu = [
    {
        label: "Redefinir Senha",
        url: "/redefinirSenha",
        disabled: "false",
        icon:<LockResetIcon color='primary'/>
    },
    {
        label: "Configurações",
        url: "/sistema",
        disabled: "false",
        icon: <MiscellaneousServicesIcon color='primary' />
    },
    {
        label: "Dashboard",
        url: "/dashboard",
        disabled: "false",
        icon: <DashboardIcon color='primary' />
    },
    {
        label: "Reembolso",
        url: "/reembolso",
        disabled: "false",
        icon: <CurrencyExchangeTwoToneIcon color='primary' />
    },
    {
        label: "Fatura",
        url: "/fatura",
        disabled: "false",
        icon: <RequestPageIcon color='primary' />
    }
];

//const urlBackend = 'http://localhost:8080'
const urlBackend = 'https://faturas.mondeeconsolidadora.com.br'

const TypePublicPlaces = [
    "Aeroporto", "Alameda", "Área", "Avenida", "Campo", "Chácara", "Colônia", "Condomínio",
    "Conjunto", "Distrito", "Esplanada", "Estação", "Estrada", "Favela", "Fazenda", "Feira",
    "Jardim", "Ladeira", "Lago", "Lagoa", "Largo", "Loteamento", "Morro", "Núcleo", "Parque",
    "Passarela", "Pátio", "Praça", "Quadra", "Recanto", "Residencial", "Rodovia", "Rua",
    "Setor", "Sítio", "Travessa", "Trecho", "Trevo", "Vale", "Vereda", "Via", "Viaduto",
    "Viela", "Vila"
]

const Estados = [
    "Acre",
    "Alagoas",
    "Amapá",
    "Amazonas",
    "Bahia",
    "Ceará",
    "Distrito Federal",
    "Espírito Santo",
    "Goiás",
    "Maranhão",
    "Mato Grosso",
    "Mato Grosso do Sul",
    "Minas Gerais",
    "Pará",
    "Paraíba",
    "Paraná",
    "Pernambuco",
    "Piauí",
    "Rio de Janeiro",
    "Rio Grande do Norte",
    "Rio Grande do Sul",
    "Rondônia",
    "Roraima",
    "Santa Catarina",
    "São Paulo",
    "Sergipe",
    "Tocantins",
]


export { LinksMenu, TypePublicPlaces, Estados, urlBackend }


