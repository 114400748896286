import React, { useState, useEffect, useRef } from 'react';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import TemporaryDrawer from "../../main/TemporayDrawer";
import Footer from "../../main/Footer";
import styled from "styled-components";
import { motion } from "framer-motion";
import CurrencyExchangeTwoToneIcon from '@mui/icons-material/CurrencyExchangeTwoTone';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import { getCodEmpUser } from '../../api/services/AuthService';
import DashboardService from '../../api/services/DashboardService';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Link, useNavigate } from 'react-router-dom'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title, BarElement, LinearScale, CategoryScale, PointElement, LineElement, BarController, LineController } from 'chart.js';
import { Pie, Chart, getElementAtEvent } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';


ChartJS.register(ArcElement, Tooltip, Legend, Title, BarElement, LinearScale, CategoryScale, ChartDataLabels, PointElement, LineElement, BarController, LineController);

const Header = styled(Box)`   
    margin-top:10px;
`
const Layout = styled.div`   
    background-color:white;
    font-weight: bold;
    width:95%;
    height:900px;
    top:80px;
    margin-top:20px;
    align-items: center;
    justify-content: center;
    margin:0 auto;
    margin-top:100px;
`

const Cards = styled.div`   
    width:100%
`

const Titulo = styled(Typography)`
    color:#4F001E;
    font-weight:bold;
    width:100%;
    margin-botton:20px;
`
const Totalizador = styled(Typography)`
    text-align: center;
    color:#4F001E;
`

const TituloTotalizador = styled(Typography)`
    text-align: center;
    color:#4F001E;
`

const LinkDash = styled(Link)`
    margin: 0;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    display: block;
    color: inherit;
`

const DashBoard = () => {
    const [totalSolicitacoes, setTotalSoliciacoes] = useState("");
    const [totalSolicitacoesSolicitadas, setTotalSoliciacoesSolicitadas] = useState("");
    const [totalSolicitacoesEnviadasCia, setTotalSoliciacoesEnviadasCia] = useState("");
    const [totalSolicitacoesCanceladas, setTotalSoliciacoesCanceladas] = useState("");
    const [totalSolicitacoesProcessadas, setTotalSoliciacoesProcessadas] = useState("");
    const [totalFaturasAereas, setTotalFaturasAereas] = useState("");
    const [totalFaturasTerrestre, setTotalFaturasTerrestre] = useState("");
    const [totalAFaturarAereas, setTotalAFaturarAereas] = useState("");
    const [totalAFaturarTerrestre, setTotalAFaturarTerrestre] = useState("");
    const [somaValoresAereos, setSomaValoresAereos] = useState("");
    const [somaValoresTerrestres, setSomaValoresTerrestres] = useState("");
    const [somaValoresAereosAFaturar, setSomaValoresAereosAFaturar] = useState("");
    const [somaValoresTerrestresAFaturar, setSomaValoresTerrestresAFaturar] = useState("");
    const [periodosAereosLastYear, setPeriodosAereosLastYear] = useState([]);
    const [valoresAereosLastYear, setValoresAereosLastYear] = useState([]);
    const [valoresTerrestresLastYear, setValoresTerrestresLastYear] = useState([]);
    const [quantidadeAereosLastYear, setQuantidadeAereosLastYear] = useState([]);
    const [quantidadeTerrestresLastYear, setQuantidadeTerrestresLastYear] = useState([]);


    const [open, setOpen] = useState(false);
    const service = new DashboardService();

    useEffect(() => {
        uploadCounters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const uploadCounters = () => {
        handleToggle();
        let user = getCodEmpUser();
        service.getTotalizadores({
            codemp: user
        }).then(response => {
            setTotalSoliciacoes(response.data.totalReembolsos);
            setTotalSoliciacoesCanceladas(response.data.reembolsosCancelados);
            setTotalSoliciacoesEnviadasCia(response.data.reembolsosEnviadosCias);
            setTotalSoliciacoesProcessadas(response.data.reembolsosProcessados);
            setTotalSoliciacoesSolicitadas(response.data.reembolsosSolicitados);
            setTotalFaturasAereas(response.data.faturasAereas);
            setTotalFaturasTerrestre(response.data.faturasTerrestres);
            setTotalAFaturarAereas(response.data.afaturarAereas);
            setTotalAFaturarTerrestre(response.data.afaturarTerrestres);
            setSomaValoresAereos(response.data.sumFaturasAereos != null ? response.data.sumFaturasAereos.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "R$ 0,00");
            setSomaValoresTerrestres(response.data.sumFaturasTerrestres != null ? response.data.sumFaturasTerrestres.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "R$ 0,00");
            setSomaValoresAereosAFaturar(response.data.sumAfaturarAereos != null ? response.data.sumAfaturarAereos.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "R$ 0,00");
            setSomaValoresTerrestresAFaturar(response.data.sumAfaturarTerrestre != null ? response.data.sumAfaturarTerrestre.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "R$ 0,00");
            setPeriodosAereosLastYear(response.data.dataChartFaturaAereos.map(function (periodo) {
                return periodo.mes + "/" + periodo.ano;
            }));
            setValoresAereosLastYear(response.data.dataChartFaturaAereos.map(function (periodo) {
                return periodo.somaValores;
            }));
            setValoresTerrestresLastYear(response.data.dataChartFaturaTerrestres.map(function (periodo) {
                return periodo.somaValores;
            }));
            setQuantidadeAereosLastYear(response.data.dataChartFaturaAereos.map(function (periodo) {
                return periodo.quantidade;
            }));
            setQuantidadeTerrestresLastYear(response.data.dataChartFaturaTerrestres.map(function (periodo) {
                return periodo.quantidade;
            }))
        }).catch(erro => {
            console.log('Error name: ', erro.name)
            console.log('Error message: ', erro.message)
            console.log('Error stack: ', erro.stack)
        }).finally(() => {
            handleClose();
            console.log(periodosAereosLastYear)
        })
    };

    const navigate = useNavigate();
    const chartRefPie = useRef();
    const chartRefBar = useRef();

    const onClick = (event) => {
        const selection = getElementAtEvent(chartRefPie.current, event);
        const { index } = selection[0];
        const status = data.labels[index];
        navigate('/reembolso', { replace: true, state: { status: status } })
    }

    const meses = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novemnro', 'dezembro']

    const onClickFaturas = (event) => {
        const selection = getElementAtEvent(chartRefBar.current, event);
        const { index } = selection[0];
        const periodo = periodosAereosLastYear[index];
        let data = periodo.split("/");

        const dataInicio = new Date(data[1], meses.indexOf(data[0]), 1);
        const dataFim = new Date(dataInicio.getFullYear(), dataInicio.getMonth() + 1, 0);

        let produto = selection[0].datasetIndex === 0 || selection[0].datasetIndex === 2 ? "AEREO" : "TERRESTRE";

        navigate('/fatura', { replace: true, state: { produto: produto, invoiceNumber: '', disabledAFaturar: true, dataInicio: dataInicio, dataFim: dataFim } })
    }

    const data = {
        labels: ['Solicitadas', 'Enviadas Cia', 'Canceladas', 'Processadas'],
        datasets: [
            {
                label: '# of Votes',
                data: [totalSolicitacoesSolicitadas, totalSolicitacoesEnviadasCia, totalSolicitacoesCanceladas, totalSolicitacoesProcessadas],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const handleToggle = () => {
        setOpen(!open);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <TemporaryDrawer />
            <motion.div
                className='reembolso'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Layout className="container">
                    <Header>
                        <Titulo variant="h5" gutterBottom component="div">
                            DASHBOARD
                        </Titulo>
                    </Header>
                    <Cards>
                        <Card sx={{ float: "left", border: '1px solid #E0FFFF', width: '28%', minHeight: '40em', backgroundColor: "#F5F5F5", flexGrow: 1, marginTop: 1 }}>
                            <LinkDash to='/reembolso'>
                                <CardHeader
                                    sx={{ height: '20px', paddingLeft: 2, paddingTop: 4, paddingBottom: 4 }}
                                    titleTypographyProps={{ fontSize: "20px", color: "#4F001E" }}
                                    title="SOLICITAÇÕES DE REEMBOLSO"
                                    avatar={
                                        <Avatar variant="square" sx={{ backgroundColor: "#4F001E", width: "30px", height: "30px" }} aria-label="recipe">
                                            <CurrencyExchangeTwoToneIcon />
                                        </Avatar>
                                    }
                                >
                                </CardHeader>
                            </LinkDash>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <LinkDash to='/reembolso'>
                                            <Totalizador variant="h1" gutterBottom component="div" >
                                                {totalSolicitacoes}
                                            </Totalizador>
                                        </LinkDash>
                                        <Pie data={data} onClick={onClick} ref={chartRefPie} options={{
                                            responsive: true,
                                            locale: 'pt-BR',
                                            plugins: {
                                                legend: {
                                                    position: 'top',
                                                },
                                                title: {
                                                    display: true,
                                                    text: 'Status Solicitações',
                                                },
                                                datalabels: {
                                                    color: function (ctx) {
                                                        // use the same color as the border
                                                        return ctx.dataset.borderColor
                                                    },
                                                    formatter: function (value, context) {
                                                        return value === 0 ? null : value;
                                                    },
                                                    font: {
                                                        weight: 'bold',
                                                        size: '18px'
                                                    }
                                                }
                                            }
                                        }} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        <Card sx={{ float: "left", border: '1px solid #E0FFFF', width: '70%', minHeight: '40em', backgroundColor: "#F5F5F5", flexGrow: 1, marginTop: 1, marginLeft: 1 }}>
                            <LinkDash to='/fatura'>
                                <CardHeader
                                    sx={{ height: '20px', paddingLeft: 2, paddingTop: 4, paddingBottom: 4 }}
                                    titleTypographyProps={{ fontSize: "24px", color: "#4F001E" }}
                                    title="FATURAS"
                                    avatar={
                                        <Avatar variant="square" sx={{ backgroundColor: "#4F001E", width: "30px", height: "30px" }} aria-label="recipe">
                                            <RequestPageIcon />
                                        </Avatar>
                                    }
                                >
                                </CardHeader>
                            </LinkDash>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={3} md={3}>
                                        <LinkDash to='/fatura' state={{ produto: 'AEREO', invoiceNumber: '', disabledAFaturar: true }}>
                                            <TituloTotalizador variant="h7" gutterBottom component="div">AÉREOS</TituloTotalizador>
                                            <Totalizador variant="h7" gutterBottom component="div" >
                                                {totalFaturasAereas} - {somaValoresAereos}
                                            </Totalizador>
                                        </LinkDash>
                                    </Grid>
                                    <Grid item xs={3} md={3}>
                                        <LinkDash to='/fatura' state={{ produto: 'TERRESTRE', invoiceNumber: '', disabledAFaturar: true }}>
                                            <TituloTotalizador variant="h7" gutterBottom component="div">TERRESTRES</TituloTotalizador>
                                            <Totalizador variant="h7" gutterBottom component="div" >
                                                {totalFaturasTerrestre} - {somaValoresTerrestres}
                                            </Totalizador>
                                        </LinkDash>
                                    </Grid>
                                    <Grid item xs={3} md={3}>
                                        <LinkDash to='/fatura' state={{ produto: 'AEREO', invoiceNumber: 0, disabledAFaturar: false }}>
                                            <TituloTotalizador variant="h7" gutterBottom component="div">AÉREOS À FATURAR</TituloTotalizador>
                                            <Totalizador variant="h7" gutterBottom component="div" >
                                                {totalAFaturarAereas} - {somaValoresAereosAFaturar}
                                            </Totalizador>
                                        </LinkDash>
                                    </Grid>
                                    <Grid item xs={3} md={3}>
                                        <LinkDash to='/fatura' state={{ produto: 'TERRESTRE', invoiceNumber: 0, disabledAFaturar: false }}>
                                            <TituloTotalizador variant="h7" gutterBottom component="div">TERRESTRES À FATURAR</TituloTotalizador>
                                            <Totalizador variant="h7" gutterBottom component="div" >
                                                {totalAFaturarTerrestre} - {somaValoresTerrestresAFaturar}
                                            </Totalizador>
                                        </LinkDash>
                                    </Grid>
                                </Grid>
                                <Chart type='bar' onClick={onClickFaturas} ref={chartRefBar} options={{
                                    responsive: true,
                                    locale: 'pt-BR',
                                    scales: {
                                        y: {
                                            ticks: {
                                                callback: (value, index, values) => {
                                                    return new Intl.NumberFormat('pt-BR', {
                                                        style: 'currency',
                                                        currency: 'BRL',
                                                        maximumFractionDigits: 2
                                                    }).format(value);
                                                }
                                            },
                                            position: 'left'
                                        },
                                        y1: {
                                            position: 'right',
                                            grid: {
                                                drawOnChartArea: false,
                                            },
                                        },
                                    },
                                    plugins: {
                                        legend: {
                                            position: 'top',
                                        },
                                        title: {
                                            display: true,
                                            text: 'Resultado Mensal Último Ano',
                                        },
                                        datalabels: {
                                            labels: {
                                                title: null
                                            }
                                        },
                                        tooltip: {
                                            callbacks: {
                                                label: function (context) {
                                                    if (context.dataset.yAxisID === 'y') {
                                                        return 'Faturamento: ' + new Intl.NumberFormat('pt-BR', {
                                                            style: 'currency',
                                                            currency: 'BRL',
                                                            maximumFractionDigits: 2
                                                        }).format(context.parsed.y);
                                                    } else {
                                                        return context.parsed.y + ' Faturas';
                                                    }
                                                }
                                            }
                                        }
                                    },
                                }} data={{
                                    labels: periodosAereosLastYear,
                                    datasets: [
                                        {
                                            label: 'Faturamento Aéreo',
                                            data: valoresAereosLastYear,
                                            backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                            type: 'bar',
                                            yAxisID: 'y',
                                        },
                                        {
                                            label: 'Faturamento Terrestre',
                                            data: valoresTerrestresLastYear,
                                            backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                            type: 'bar',
                                            yAxisID: 'y',
                                        },
                                        {
                                            type: 'line',
                                            label: 'Qtd. Aéreos',
                                            borderColor: 'rgb(255, 99, 132)',
                                            backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                            borderWidth: 2,
                                            data: quantidadeAereosLastYear,
                                            yAxisID: 'y1',
                                        },
                                        {
                                            type: 'line',
                                            label: 'Qtd. Terrestres',
                                            borderColor: 'rgb(53, 162, 235)',
                                            backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                            borderWidth: 2,
                                            data: quantidadeTerrestresLastYear,
                                            yAxisID: 'y1',
                                        },
                                    ],
                                }} />
                            </CardContent>
                        </Card>
                    </Cards>
                </Layout>
            </motion.div>
            <Footer />
        </>
    );
};

export default DashBoard;