import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import { TextField } from '@material-ui/core';
import { verifyFirstLogin, isSessionExpired, setSessionActive, clearStoragePortal, setPasswordFull, setPasswordDefault, verifyPermission } from '../../api/services/AuthService'
import Alert from '@mui/material/Alert';
import aviao from '../../../assets/images/aviao.jpeg';
import { Link, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../theme/Theme';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import logo from '../../../assets/images/logo.png';

import styled from "styled-components";

import LoginService from '../../api/services/LoginService';

const DivLogin = styled.div`
    position:fixed;
    z-index:0;
    width:100%;
    height:100%;
    background-attachment: fixed;
    background-size: cover;
    background-position: right top;
  }
`

const DivLoginBack = styled.div`
    background-image:url(${aviao});  
    position:absolute;
    z-index:-1;
    width:100%;
    height:100%;
    background-attachment: fixed;
    background-size: cover;
    background-position: right top;
    opacity:0.3;
  }
`

const CardLogin = styled.div`
    z-index:2;
    opacity:0.8;
`
const LinkMenu = styled(Link)`
    margin: 0;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    display: block;
    color: inherit;
    text-decoration:none;
`

const CardHeaderLogin = styled(CardHeader)`
    background-color:#4F001E;
    color:white;
    font-height:bold;
    width:500px;
`

const AlertBadCredentials = styled(Alert)`
    margin-bottom:5px;
    display: ${({ isvisible }) => isvisible === 'true' ? 'flex !important' : 'none !important'}
`
const DivLogo = styled.div`
    color:#F60F9E;
    margin-bottom:20px;
    font-family: "Lucida Sans" !important;
`

const DivContent = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

const ImagemLogo = styled.img`
    width:480px;
    height:148px;
`
export default function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);
    const [variant, setVariant] = useState("error");
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);

    let navigate = useNavigate();

    useEffect(() => {
        const statusSessionExpired = () => {
            setVariant("warning");
            setMessage('Sessão Encerrada!');
            setError(true)
            clearStoragePortal();
        }
        if (error !== true && isSessionExpired() === true) {
            statusSessionExpired();
        }

    }, [variant, message, error]);

    const servico = new LoginService()

    return (
        <form onSubmit={(event) => {
            setOpen(true);
            setError(false)
            event.preventDefault();
            servico.autenticar({
                username: username,
                password: password
            }).then(response => {
                const jsonToken = response.data;
                verifyPermission(jsonToken);
                servico.clearStorage();
                let user = verifyFirstLogin(jsonToken);
                setSessionActive();
                if (user.isReset) {
                    setPasswordFull();
                    if (user.isAdmin) {
                        navigate('/fatura');
                        navigate(0);
                    } else {
                        navigate('/dashboard');
                        navigate(0);
                    }
                } else {
                    setPasswordDefault();
                    navigate('/redefinirSenha');
                    navigate(0);
                }
            }).catch(erro => {
                setError(true);
                setVariant("error");
                if (erro.code === 401) setMessage(erro.message)
                else {
                    switch (erro?.response?.status) {
                        case 401 || 403:
                            setMessage("Credenciais Inválidas!")
                            break;
                        default:
                            setMessage("Problema ao tentar logar, entre em contato com o adm do sistema!")
                            break;
                    }
                }
            }).finally(() => {
                setOpen(false);
            })
        }}>
            <ThemeProvider theme={theme}>
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <DivLogin>
                    <DivLoginBack></DivLoginBack>
                    <DivContent>
                        <DivLogo>
                            <ImagemLogo src={logo} />
                        </DivLogo>
                        <CardLogin>
                            <Card variant="outlined">
                                <CardHeaderLogin title="PORTAL DO AGENTE" />
                                <CardContent sx={{ backgroundColor: "#F5F5F5" }}>
                                    <TextField
                                        id="username"
                                        autoComplete="off"
                                        label="Usuário"
                                        type="text"
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        placeholder="Usuário"
                                        hiddenLabel
                                        value={username}
                                        onChange={(event) => {
                                            setUsername(event.target.value);
                                        }}
                                    />

                                    <TextField
                                        id="password"
                                        autoComplete="off"
                                        required
                                        hiddenLabel
                                        label="Senha"
                                        name="senha"
                                        type="password"
                                        margin="normal"
                                        placeholder="Senha"
                                        variant="outlined"
                                        fullWidth
                                        value={password}
                                        onChange={(event) => {
                                            setPassword(event.target.value);
                                        }}
                                    />
                                    <AlertBadCredentials isvisible={error.toString()} variant="filled" severity={variant}>
                                        {message}
                                    </AlertBadCredentials>
                                    <Grid container spacing={2} style={{ marginTop: 5, marginBotton: '1em' }}>
                                        <Grid item xs={6}>
                                            <Button type="submit" variant="contained" color="primary">
                                                Logar
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <LinkMenu to='/recuperarSenha'>
                                                <ListItem>
                                                    <ListItemText sx={{ color: "#4F001E", textAlign: "right" }} primary={"Esqueci minha senha"} color='primary' />
                                                </ListItem>
                                            </LinkMenu>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </CardLogin>
                    </DivContent>
                </DivLogin>
            </ThemeProvider>
        </form>
    );
}