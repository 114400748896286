import React, { useState, useEffect } from 'react';

import TemporaryDrawer from "../../main/TemporayDrawer";
import Footer from "../../main/Footer";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Button as ButtonPrime } from 'primereact/button';
import { TextField } from '@material-ui/core';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FaturaService from '../../api/services/FaturaService';
import EmpresaService from '../../api/services/EmpresaService';
import { getCodEmpUser, isAdmin } from '../../api/services/AuthService';
import moment from 'moment'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeaderFilterSearch from '../../main/CardHeaderFilterSearch';
import { motion } from "framer-motion";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ReportFaturaService from '../../api/services/ReportFaturaService';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import BackDrop from '../../backdrop/BackDrop';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputMask from 'react-input-mask'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import SendIcon from '@mui/icons-material/Send';
import ClearIcon from '@mui/icons-material/Clear';
import { Tag } from 'primereact/tag';

import 'dayjs/locale/pt-br';

import dayjs from 'dayjs';


import styled from "styled-components";

const TextFieldCustom = styled(TextField)`

  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #4F001E;
    }
  }
`;

const CustomDesktopDatePicker = styled(DesktopDatePicker)`
& .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #4F001E;
    }
  }
`;

const TabelaModal = styled.div`
    height: 300px;
    overflow: auto;
    width: 100%;
    margin-top:10px;
`

const DialogTitleCliente = styled(DialogTitle)`   
    color:#041E42;
`

const Header = styled(Box)`   
    margin-top:10px;
`
const Layout = styled.div`   
    background-color:white;
    font-weight: bold;
    width:95%;
    min-height:500px;
    top:80px;
    margin-top:20px;
    align-items: center;
    justify-content: center;
    margin:0 auto;
    margin-top:100px;
`

const Titulo = styled(Typography)`
    color:#4F001E;
    font-weight:bold;
    width:100%;
    margin-botton:20px;    
`
const Tabela = styled.div`
    height: 700px;
    overflow: auto;
    width: 100%;
    padding-top:15px;
    margin-bottom:50px;
    
`

const ListarFatura = () => {
    const [numeroFatura, setNumeroFatura] = useState(sessionStorage.getItem("numeroFatura"));
    const [produto, setProduto] = useState(sessionStorage.getItem("produto") && sessionStorage.getItem("produto") !== '' ? sessionStorage.getItem("produto") : "TODOS");
    const [situacao, setSituacao] = useState("TODAS");
    const [open, setOpen] = useState(false);
    const [faturas, setFaturas] = useState([]);
    const [dataInicio, setDataInicio] = useState(null);
    const [dataFim, setDataFim] = useState(null);
    const [tipoData, setTipoData] = useState("TODAS");
    const [errorMessage, setErrorMessage] = useState("");
    const [openSnack, setOpenSnack] = useState(false);
    const [tipoSnack, setTipoSnack] = useState("");
    const [pagamento, setPagamento] = useState("AMBOS");
    const [openDialog, setOpenDialog] = useState(false);
    const [empresa, setEmpresa] = useState(sessionStorage.getItem("empresa") && sessionStorage.getItem("empresa") !== '' ? sessionStorage.getItem("empresa") : "");
    const [minDateVencimento, setMinDateVencimento] = useState(null);
    const [nomeEmpresa, setNomeEmpresa] = useState("");
    const [codigoEmpresa, setCodigoEmpresa] = useState("");
    const [codigoEmpresaSelecionada, setCodigoEmpresaSelecionada] = useState(sessionStorage.getItem("codigoEmpresaSelecionada") && sessionStorage.getItem("codigoEmpresaSelecionada") !== '' ? sessionStorage.getItem("codigoEmpresaSelecionada") : "");
    const [cnpjEmpresa, setCnpjEmpresa] = useState("");
    const [cpfEmpresa, setCpfEmpresa] = useState("");
    const [empresas, setEmpresas] = useState([]);
    const [empresaSelecionada, setEmpresaSelecionada] = useState();
    const [backdropDialog, setBackdropDialog] = useState(false);
    const [rowClick, setRowClick] = useState(false);
    const [selectedFatura, setSelectedFatura] = useState(null);
    const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
    const paginatorRight = <Button type="button" icon="pi pi-download" text />;

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const faturaService = new FaturaService();
    const empresaService = new EmpresaService();

    let navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location && location.state) {
            clear();
            if (location.state.produto) {
                setRowClick(false);
                setProduto(location.state.produto);
                setNumeroFatura(location.state.invoiceNumber);
                if (location.state.dataInicio && location.state.dataFim) {
                    setDataInicio(location.state.dataInicio);
                    setDataFim(location.state.dataFim);
                    setTipoData("DATA_EMISSAO");
                    listarSimplificado({
                        invoiceType: location.state.produto,
                        empfat: getCodEmpUser(),
                        invoiceNumber: location.state.invoiceNumber,
                        disabledAFaturar: location.state.disabledAFaturar,
                        dataInicio: location.state.dataInicio,
                        dataFim: location.state.dataFim,
                        tipoData: 'DATA_EMISSAO',
                        invoiceSituation: 'TODAS',
                    });
                } else {
                    listarSimplificado({
                        invoiceType: location.state.produto,
                        empfat: getCodEmpUser(),
                        invoiceNumber: location.state.invoiceNumber,
                        disabledAFaturar: location.state.disabledAFaturar,
                        tipoData: 'TODAS',
                        invoiceSituation: 'TODAS',
                    });
                }
            }

        } else {
            if (!isAdmin())
                listar();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const validarListagemCliente = () => {
        let retorno = true;
        if (!nomeEmpresa && !codigoEmpresa && !cnpjEmpresa && !cpfEmpresa) {
            setErrorMessage("Preencha pelo menos um campo de filtro para realizar a pesquisa de clientes!");
            setOpenSnack(true);

            retorno = false;
        }

        return retorno;
    }

    const validarFaturaSelecionada = () => {
        let retorno = true;
        if (!selectedFatura) {
            setErrorMessage("Selecione uma fatura!");
            setOpenSnack(true);
            setTipoSnack("warning")

            retorno = false;
        }
        return retorno;
    }

    const findEmpresas = () => {
        if (validarListagemCliente()) {
            setBackdropDialog(true);
            empresaService.listarEmpresas({
                codemp: codigoEmpresa === '' ? null : codigoEmpresa,
                nome: nomeEmpresa === '' ? null : nomeEmpresa,
                cnpj: cnpjEmpresa === '' ? null : cnpjEmpresa.replace(/[^\d]+/g, ''),
                cpf: cpfEmpresa === '' ? null : cpfEmpresa.replace(/[^\d]+/g, ''),
            }).then(response => {
                setEmpresas(response.data);
            }).catch(erro => {
                console.log('Error name: ', erro.name)
                console.log('Error message: ', erro.message)
                console.log('Error stack: ', erro.stack)
            }).finally(() => {
                handleClose();
                setBackdropDialog(false);
            })
        }
    }

    const handleView = () => {
        if (validarFaturaSelecionada()) {
            handleToggle();
            faturaService.detalhar(
                {
                    invoiceNumber: selectedFatura.numfat,
                    empfat: isAdmin() ? codigoEmpresaSelecionada : getCodEmpUser(),
                    dataven: moment(selectedFatura.dataVen).format('DD/MM/YYYY'),
                    codest: selectedFatura.codest,
                    invoiceType: faturaService.removeAcento(selectedFatura.invoiceType).toUpperCase(),
                    condpag: selectedFatura.condpag,
                    valor: selectedFatura.valor,
                }
            ).then(response => {
                response.data.codest = selectedFatura.codest;
                response.data.condpag = selectedFatura.condpag;
                response.data.dataVen = moment(selectedFatura.dataVen).format('DD/MM/YYYY');
                navigate('/detalharFatura', { replace: true, state: { fatura: response.data } })
            }).catch(erro => {
                //setError(true);
            }).finally(() => {
                handleClose();
            });
        }
    }

    const selecionarEmpresa = () => {
        if (!empresaSelecionada) {
            setErrorMessage('Nenhum Cliente Selecionado!');
            setOpenSnack(true);
            setTipoSnack("warning")
        } else {
            setEmpresa(empresaSelecionada.codemp + " - " + empresaSelecionada.nome);
            setCodigoEmpresaSelecionada(empresaSelecionada.codemp);
            setOpenDialog(false);
            setOpenSnack(true);
            setTipoSnack("success")
            setErrorMessage('Empresa ' + empresaSelecionada.nome + ' selecionada!');
        }
    }

    const service = new ReportFaturaService();

    const downloadBoleto = () => {
        if (validarFaturaSelecionada()) {
            setOpen(true);
            service.getFile('/getBoleto/',
                {
                    invoiceNumber: selectedFatura.numfat,
                    invoiceType: faturaService.removeAcento(selectedFatura.invoiceType).toUpperCase(),
                    empfat: isAdmin() ? codigoEmpresaSelecionada : getCodEmpUser(),
                    codest: selectedFatura.codest,
                }
            ).then(response => {
                const blobFile = new Blob([response.data], { type: response.headers['content-type'] });
                let url = window.URL.createObjectURL(blobFile);
                let a = document.createElement('a');
                a.href = url;
                a.download = selectedFatura.numfat + '.pdf';
                a.click();
                setErrorMessage("Download de boleto realizado com sucesso!");
                setOpenSnack(true);
                setTipoSnack("success");
            }).catch(erro => {
                switch (erro?.response?.status) {
                    case 404:
                        setErrorMessage("Boleto não encontrado na pasta designada!");
                        break;
                    default:
                        setErrorMessage("Erro ao carregar o boleto!Contate o adm do sistema!");
                        break;
                }
                setOpenSnack(true);
                setTipoSnack("error");
            }).finally(() => {
                setOpen(false);
            })
        }
    }

    const downloadPdf = () => {
        if (validarFaturaSelecionada()) {
            setOpen(true);
            service.getFile('/getPdf/',
                {
                    invoiceNumber: selectedFatura.numfat,
                    empfat: isAdmin() ? codigoEmpresaSelecionada : getCodEmpUser(),
                    dataven: moment(selectedFatura.dataVen).format('DD/MM/YYYY'),
                    codest: selectedFatura.codest,
                    invoiceType: faturaService.removeAcento(selectedFatura.invoiceType).toUpperCase(),
                    condpag: selectedFatura.condpag,
                    valor: selectedFatura.valor,
                }
            ).then(response => {
                const blobFile = new Blob([response.data], { type: response.headers['content-type'] });
                let url = window.URL.createObjectURL(blobFile);
                let a = document.createElement('a');
                a.href = url;
                a.download = selectedFatura.numfat === 0 ? 'aFaturar.pdf' : selectedFatura.numfat + '.pdf';
                a.click();
                setErrorMessage("Download do pdf da fatura realizado com sucesso!");
                setOpenSnack(true);
                setTipoSnack("success");
            }).catch(erro => {
                console.log('Error name: ', erro.name)
                console.log('Error message: ', erro.message)
                console.log('Error stack: ', erro.stack)
            }).finally(() => {
                setOpen(false);
            })
        }
    }

    const downloadPlanilha = () => {
        if (validarFaturaSelecionada()) {
            setOpen(true);
            service.getFile('/getCsv/',
                {
                    invoiceNumber: selectedFatura.numfat,
                    empfat: isAdmin() ? codigoEmpresaSelecionada : getCodEmpUser(),
                    dataven: moment(selectedFatura.dataVen).format('DD/MM/YYYY'),
                    codest: selectedFatura.codest,
                    invoiceType: faturaService.removeAcento(selectedFatura.invoiceType).toUpperCase(),
                    condpag: selectedFatura.condpag,
                    valor: selectedFatura.valor,
                }
            ).then(response => {
                const blobFile = new Blob([response.data], { type: response.headers['content-type'] });
                let url = window.URL.createObjectURL(blobFile);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'planilha_' + selectedFatura.numfat + '.csv';
                a.click();
            }).catch(erro => {
                console.log('Error name: ', erro.name)
                console.log('Error message: ', erro.message)
                console.log('Error stack: ', erro.stack)
            }).finally(() => {
                setOpen(false);
            })
        }
    }

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };

    const saveStorage = () => {
        sessionStorage.setItem("numeroFatura", numeroFatura);
        sessionStorage.setItem("codigoEmpresaSelecionada", codigoEmpresaSelecionada);
        sessionStorage.setItem("empresa", empresa);
        sessionStorage.setItem("dataInicio", dataInicio);
        sessionStorage.setItem("dataFim", dataFim);
        sessionStorage.setItem("tipoData", tipoData);
        sessionStorage.setItem("pagamento", pagamento);
        sessionStorage.setItem("stored", true);
    }

    const validarListagem = () => {
        let retorno = true;
        let message = '';
        if (isAdmin() && !empresa) {
            message += "Selecione a empresa para exibir as faturas!\n";
            retorno = false;
        }
        if ((dataInicio || dataFim) && tipoData === 'TODAS') {
            message += "Selecione o Período para pesquisa!\n";
            retorno = false;
        } if (tipoData !== 'TODAS' && (!dataInicio || !dataFim)) {
            message += "Preencha o Data Início e a Data Fim!\n";
            retorno = false;
        }
        if (!retorno) {
            setErrorMessage(message);
            setOpenSnack(true);
            setTipoSnack("warning");
        }
        return retorno;
    }

    const clear = () => {
        setFaturas([]);
        setProduto("TODOS");
        setNumeroFatura("");
        clearStorage();
        setTipoData("TODAS");
        setDataInicio(null);
        setDataFim(null);
        setPagamento("AMBOS");
        setEmpresa("");
        setSituacao("TODAS");
        setSelectedFatura(null);
        clearModal();
    }

    const handleCloseDialog = () => {
        setEmpresaSelecionada("");
        setCodigoEmpresa("");
        setEmpresa("");
        setEmpresas([]);
        setOpenDialog(false);
    };

    const clearModal = () => {
        setEmpresaSelecionada("");
        setCodigoEmpresa("");
        setEmpresas([]);
        setNomeEmpresa("");
        setCnpjEmpresa("");
        setCpfEmpresa("");
    }

    const clearStorage = () => {
        sessionStorage.setItem("numeroFatura", '');
        sessionStorage.setItem("produto", "TODOS");
    }

    const handleToggle = () => {
        setOpen(!open);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const listarSimplificado = (params) => {
        handleToggle();
        if (validarListagem()) {
            faturaService.listar(
                {
                    invoiceType: params.invoiceType,
                    invoiceNumber: params.invoiceNumber === '' ? null : params.invoiceNumber,
                    empfat: params.empfat,
                    disabledAFaturar: params.disabledAFaturar,
                    pagamento: 'AMBOS',
                    dataInicio: params.dataInicio,
                    dataFim: params.dataFim,
                    tipoData: params.tipoData,
                    cancelado: false,
                    invoiceSituation: params.invoiceSituation,
                }
            ).then(response => {
                setFaturas(response.data);
                saveStorage();
            }).catch(erro => {
                console.log('Error name: ', erro.name)
                console.log('Error message: ', erro.message)
                console.log('Error stack: ', erro.stack)
            }).finally(() => {
                handleClose();
            })
        } else {
            handleClose();
        }
    };

    const listar = () => {
        handleToggle();
        if (validarListagem()) {
            faturaService.listar({
                invoiceNumber: numeroFatura === '' ? null : numeroFatura,
                invoiceType: produto === '' ? null : produto,
                cancelado: false,
                empfat: isAdmin() ? codigoEmpresaSelecionada : getCodEmpUser(),
                dataInicio: dataInicio,
                dataFim: dataFim,
                tipoData: tipoData,
                pagamento: pagamento,
                invoiceSituation: situacao,
                disabledAFaturar: false
            }).then(response => {
                setFaturas(response.data);
                saveStorage();
            }).catch(erro => {
                console.log('Error name: ', erro.name)
                console.log('Error message: ', erro.message)
                console.log('Error stack: ', erro.stack)
            }).finally(() => {
                handleClose();
            })
        } else {
            handleClose();
        }
    };

    const handleChangeDataInicio = (newValue) => {
        setDataInicio(newValue);
    };

    const handleChangeDataFim = (newValue) => {
        setDataFim(newValue);
    };


    const dataFaturaBodyTemplate = (rowData) => {
        return rowData.dataFatura ? moment(rowData.dataFatura).format('DD/MM/YYYY') : "-";
    }

    const dataVenBodyTemplate = (rowData) => {
        return rowData.dataVen ? moment(rowData.dataVen).format('DD/MM/YYYY') : "-";
    }

    const moneyBodyTemplate = (rowData) => {
        const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
        const formatNumber = new Intl.NumberFormat('pt-BR', options)
        let value = rowData.valor;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const statusBodyTemplate = (rowData) => {
        return <Tag className="mr-2" rounded value={rowData.pago} severity={getPayment(rowData.pago).severity} icon={getPayment(rowData.pago).icon} />;
    };



    const getPayment = (status) => {
        // eslint-disable-next-line default-case
        if (status === 'Sim') {
            return {
                severity: 'success',
                icon: 'pi pi-check'
            }
        } else {
            return {
                severity: 'danger',
                icon: 'pi pi-times'
            }
        }
    };

    return (
        <>
            <TemporaryDrawer />
            <motion.div
                className='fatura'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>

                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                    <CircularProgress style={{color:'#4F001E'}}/>
                    <CircularProgress color="inherit" />
                    <CircularProgress style={{color:'#4F001E'}}/>
                </Backdrop>
                <Layout className="container">
                    <Header>
                        <Titulo variant="h5" gutterBottom component="div">
                            LISTAGEM DE FATURAS
                        </Titulo>
                    </Header>
                    <Card sx={{ minWidth: 800, backgroundColor: "#F5F5F5", flexGrow: 1 }}>
                        <CardContent>
                            <CardHeaderFilterSearch />
                            <Box component="form">
                                <TextFieldCustom
                                    id="numeroFatura"
                                    type="text"
                                    sx={{ width: 200, backgroundColor: "#FFFF" }}
                                    label="Nº Fatura"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    value={numeroFatura}
                                    onChange={(e) => {
                                        let input = e.target.value;
                                        if (!input || (input[input.length - 1].match('[0-9]') && input[0].match('[1-9]')))
                                            setNumeroFatura(input)
                                    }}
                                />
                                <FormControl sx={{ m: 1, width: 200, marginLeft: 2.5, marginTop: 2 }} variant="outlined">
                                    <InputLabel id="labelSelectProdutos">Produto</InputLabel>
                                    <Select
                                        labelId="labelSelectProdutos"
                                        sx={{ width: 200, backgroundColor: "#FFFF" }}
                                        id="selectProdutos"
                                        value={produto}
                                        label="Status"
                                        size="small"
                                        onChange={(event) => {
                                            setProduto(event.target.value);
                                        }}
                                    >
                                        <MenuItem value={'TODOS'}>Todos</MenuItem>
                                        <MenuItem value={'AEREO'}>Aéreo</MenuItem>
                                        <MenuItem value={'TERRESTRE'}>Terrestre</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ m: 1, width: 200, marginLeft: 2.5, marginTop: 2 }} variant="outlined">
                                    <InputLabel id="labelSelectPagamentos">Pagamento</InputLabel>
                                    <Select
                                        labelId="labelSelectPagamentos"
                                        sx={{ width: 200, backgroundColor: "#FFFF" }}
                                        id="selectProdutos"
                                        value={pagamento}
                                        label="Pagamento"
                                        size="small"
                                        onChange={(event) => {
                                            setPagamento(event.target.value);
                                        }}
                                    >
                                        <MenuItem value={'AMBOS'}>Ambos</MenuItem>
                                        <MenuItem value={'PAGO'}>Pago</MenuItem>
                                        <MenuItem value={'ABERTO'}>Aberto</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ m: 1, width: 200, marginLeft: 2.5, marginTop: 2 }} variant="outlined">
                                    <InputLabel id="labelSelectSituacao">Situação</InputLabel>
                                    <Select
                                        labelId="labelSelectSituacao"
                                        sx={{ width: 200, backgroundColor: "#FFFF" }}
                                        id="selectSituacao"
                                        value={situacao}
                                        label="Situação"
                                        size="small"
                                        onChange={(event) => {
                                            setSituacao(event.target.value);
                                        }}
                                    >
                                        <MenuItem value={'TODAS'}>Todas</MenuItem>
                                        <MenuItem value={'AFATURAR'}>À Faturar</MenuItem>
                                        <MenuItem value={'FATURADA'}>Faturada</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box component="form">
                                <FormControl sx={{ width: 200, marginTop: 2 }} variant="outlined">
                                    <InputLabel id="labelSelectTipoDatas">Período</InputLabel>
                                    <Select
                                        labelId="labelSelectTipoDatas"
                                        id="selectTipoDatas"
                                        value={tipoData}
                                        label="periodo"
                                        size="small"
                                        sx={{ backgroundColor: "#FFFF" }}
                                        onChange={(event) => {
                                            if (event.target.value === 'DATA_VENCIMENTO') {
                                                setMinDateVencimento(dayjs('2023-01-01'));
                                            } else {
                                                setMinDateVencimento(null)
                                            }
                                            setTipoData(event.target.value);
                                        }}
                                    >
                                        <MenuItem value={'TODAS'}>Selecione</MenuItem>
                                        <MenuItem value={'DATA_EMISSAO'}>Data Emissão</MenuItem>
                                        <MenuItem value={'DATA_VENCIMENTO'}>Data Vencimento</MenuItem>
                                    </Select>
                                </FormControl>
                                <LocalizationProvider adapterLocale="pt-br" dateAdapter={AdapterDayjs} >
                                    <CustomDesktopDatePicker
                                        label="Data Início"
                                        inputFormat="DD/MM/YYYY"
                                        value={dataInicio}
                                        minDate={minDateVencimento}
                                        onChange={handleChangeDataInicio}
                                        renderInput={(params) => <TextField size="small" sx={{ marginTop: 2, marginLeft: 2.5, backgroundColor: "#FFFF" }}  {...params} />}
                                    />
                                </LocalizationProvider>
                                <LocalizationProvider adapterLocale="pt-br" dateAdapter={AdapterDayjs} >
                                    <CustomDesktopDatePicker
                                        label="Data Fim"
                                        inputFormat="DD/MM/YYYY"
                                        value={dataFim}
                                        onChange={handleChangeDataFim}
                                        renderInput={(params) => <TextField size="small" sx={{ marginTop: 2, marginLeft: 2.5, backgroundColor: "#FFFF" }}  {...params} />}
                                    />
                                </LocalizationProvider>
                                {isAdmin() ?
                                    <TextField
                                        id="empresaAdmin"
                                        sx={{ width: 500, backgroundColor: "#FFFF", marginLeft: 2.5 }}
                                        label="Empresa"
                                        type="text"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        size="small"
                                        disabled
                                        value={empresa}
                                        onChange={(event) => {
                                            setEmpresa(event.target.value.replace(/[^0-9.]/g, ''));
                                        }}
                                    />
                                    : null}
                                {isAdmin() ?
                                    <Tooltip title="Clique Para Pesquisar Agência!">
                                        <Fab onClick={handleClickOpen} sx={{ m: 1, marginTop: 2, marginLeft: 2 }} variant="contained" color="primary" aria-label="add" size="small">
                                            <AddBusinessIcon />
                                        </Fab>
                                    </Tooltip>
                                    : null}
                            </Box>
                            <Button startIcon={<SendIcon />} size="small" sx={{ width: 100, marginTop: 2.5 }} onClick={listar} color="primary" variant="contained">Listar</Button>
                            <Button startIcon={<ClearIcon />} size="small" sx={{ width: 100, marginLeft: 2.5, marginTop: 2.5 }} onClick={clear} color="primary" variant="contained">Limpar</Button>
                        </CardContent>
                    </Card>
                    {(faturas && faturas.length > 0) || !isAdmin() ?
                        <Card sx={{ minWidth: 400, backgroundColor: "#F5F5F5", flexGrow: 1, marginTop: 2, height: 700 }}>
                            <CardContent>
                                <div className="card flex flex-wrap justify-content-center gap-3">
                                    <ButtonPrime disabled={!selectedFatura} onClick={handleView} style={{ backgroundColor: "#4F001E", float: "left" }} type="button" label="Detalhar" icon="pi pi-eye" />
                                    <ButtonPrime disabled={!selectedFatura} onClick={downloadPdf} style={{ marginLeft: 3, float: "left" }} className="p-button-danger" type="button" label="PDF" icon="pi pi-file-pdf" />
                                    <ButtonPrime disabled={!selectedFatura} onClick={downloadPlanilha} style={{ marginLeft: 3, float: "left" }} className="p-button-success" type="button" label="CSV" icon="pi pi-file-excel" />
                                    <ButtonPrime onClick={downloadBoleto} style={{ marginLeft: 3, float: "left"}}
                                        disabled={!selectedFatura || selectedFatura.pago === 'Sim' || selectedFatura.numfat === 0 || selectedFatura.valor === 0}
                                        className="p-button-secondary" type="button" label="Boleto" icon="pi pi-dollar" />
                                    <Typography variant="h7" gutterBottom style={{ color: "#4F001E", float: "right", marginTop: 5 }}>{selectedFatura ? selectedFatura.numfat !== 0 ? "FATURA SELECIONADA: " + selectedFatura.numfat : "PRÈ FATURA SELECIONADA! " : "NENHUMA FATURA SELECIONADA! "}</Typography>
                                </div>
                                <Tabela>
                                    <DataTable
                                        value={faturas}
                                        selectionMode={rowClick ? null : 'radiobutton'}
                                        selection={selectedFatura}
                                        responsiveLayout="scroll"
                                        emptyMessage="Nenhuma Fatura Encontrada"
                                        paginator
                                        rows={10}
                                        style={{ color: "#4F001E" }}
                                        sortMode="multiple"
                                        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                                        currentPageReportTemplate="{first} a {last} de {totalRecords}"
                                        paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}
                                        onSelectionChange={(e) => setSelectedFatura(e.value)}
                                        dataKey="key">
                                        <Column selectionMode="single" headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '3em' }}></Column>
                                        <Column field="numfat" bodyStyle={{ textAlign: "center" }} sortable headerStyle={{ backgroundColor: '#4F001E', color: 'white' }} header="Nº Fatura"></Column>
                                        <Column field="dataFatura"  body={dataFaturaBodyTemplate} bodyStyle={{ textAlign: "center" }} sortable headerStyle={{ backgroundColor: '#4F001E', color: 'white' }} header="Data Emissão"></Column>
                                        <Column field="dataVen" body={dataVenBodyTemplate} bodyStyle={{ textAlign: "center" }} sortable headerStyle={{ backgroundColor: '#4F001E', color: 'white' }} header="Data Vencimento"></Column>
                                        <Column field="valor" body={moneyBodyTemplate} bodyStyle={{ textAlign: "right" }} sortable headerStyle={{ backgroundColor: '#4F001E', color: 'white' }} header="Valor"></Column>
                                        <Column field="situacao" bodyStyle={{ textAlign: "center" }} sortable headerStyle={{ backgroundColor: '#4F001E', color: 'white' }} header="Situação"></Column>
                                        <Column field="invoiceType" bodyStyle={{ textAlign: "center" }} sortable headerStyle={{ backgroundColor: '#4F001E', color: 'white' }} header="Produto"></Column>
                                        <Column field='pago' body={statusBodyTemplate} bodyStyle={{ textAlign: "center" }} sortable headerStyle={{ backgroundColor: '#4F001E', color: 'white' }} header="Pago"></Column>
                                    </DataTable>
                                </Tabela>
                            </CardContent>
                        </Card>
                        : null}
                </Layout>
                <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
                    <Alert onClose={handleCloseSnack} severity={tipoSnack} sx={{ width: '100%', whiteSpace: 'pre-line' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </motion.div>
            <Footer />
            <Dialog maxWidth="lg" fullWidth open={openDialog} onClose={handleCloseDialog}>
                <BackDrop onOpen={backdropDialog}></BackDrop>
                <DialogTitleCliente>PESQUISA DE CLIENTES</DialogTitleCliente>
                <DialogContent >
                    <TextField
                        autoFocus
                        margin="dense"
                        sx={{ width: 100 }}
                        id="codigo"
                        label="Código"
                        fullWidth
                        variant="standard"
                        value={codigoEmpresa}
                        onChange={(e) => {
                            let input = e.target.value;
                            if (!input || (input[input.length - 1].match('[0-9]') && input[0].match('[1-9]')))
                                setCodigoEmpresa(input)
                        }}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="empresa"
                        label="Empresa"
                        sx={{ width: 400, marginLeft: 2 }}
                        fullWidth
                        variant="standard"
                        value={nomeEmpresa}
                        onChange={(event) => {
                            setNomeEmpresa(event.target.value);
                        }}
                    />
                    <InputMask mask="99.999.999/9999-99" value={cnpjEmpresa} onChange={(event) => {
                        setCnpjEmpresa(event.target.value);
                    }}>
                        {
                            inputProps => (
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="cnpj"
                                    sx={{ width: 200, marginLeft: 2 }}
                                    label="CNPJ"
                                    fullWidth
                                    variant="standard"
                                />
                            )
                        }
                    </InputMask>
                    <InputMask mask="999.999.999-99" value={cpfEmpresa} onChange={(event) => {
                        setCpfEmpresa(event.target.value);
                    }}>
                        {
                            inputProps => (
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="cpf"
                                    sx={{ width: 200, marginLeft: 2 }}
                                    label="CPF"
                                    fullWidth
                                    variant="standard"
                                />
                            )
                        }
                    </InputMask>

                    <Button sx={{ marginTop: 3, marginLeft: 2 }} color="primary" onClick={clearModal}>Limpar</Button>
                    <Button sx={{ marginTop: 3, marginLeft: 2 }} color="primary" onClick={findEmpresas}>Pesquisar</Button>
                    <TabelaModal>
                        <DataTable value={empresas}
                            selection={empresaSelecionada}
                            emptyMessage="Nenhuma Empresa Encontrada"
                            paginator rows={10}
                            onSelectionChange={e =>
                                setEmpresaSelecionada(e.value)
                            }
                            dataKey="codemp"
                            responsiveLayout="scroll"
                        >
                            <Column selectionMode="single" headerStyle={{ width: '3em' }}></Column>
                            <Column field="codemp" header="Código"></Column>
                            <Column field="nome" header="Nome"></Column>
                            <Column field="cnpj" header="CNPJ"></Column>
                            <Column field="cpf" header="CPF"></Column>
                        </DataTable>
                    </TabelaModal>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleCloseDialog}>Cancelar</Button>
                    {empresas.length > 0 ?
                        <Button color="primary" onClick={selecionarEmpresa}>Selecionar</Button>
                        : null}
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ListarFatura;