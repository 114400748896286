import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import moment from 'moment'
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import styled from "styled-components";


const SpanTitle = styled.span`
    font-size:16px;
    color:#4F001E;
    font-weight:bold;
`
const SpanData = styled.span`
    margin-right:50px;
    font-size:16px;
    color:#4F001E;
    font-weight:bold;
    
`
const FooterGroup = styled.p`
    text-align:right;
    font-size:14px;
    color:#4F001E;
    font-weight:bold;
`
const HeaderTable = styled.div`
    text-align:center;
    color:#4F001E;
    font-size:24px;
`

const IconHeader = styled(AirplaneTicketIcon)`
    margin-right:10px;
    width: 30px !important;
    height: 30px !important;
`

const HeaderTitulo = styled.span`
    vertical-align:text-bottom;
    margin-bottom:10px;
`


const TabelaBilhetes = (props) => {
    const [bilhetes, setBilhetes] = useState([]);
    const [totalTarifa, setTotalTarifa] = useState(0.00);
    const [totalTaxa, setTotalTaxa] = useState(0.00);
    const [totalCartao, setTotalCartao] = useState(0.00);
    const [totalComissao, setTotalComissao] = useState(0.00);
    const [totalIncentivo, setTotalIncentivo] = useState(0.00);
    const [totalDesconto, setTotalDesconto] = useState(0.00);
    const [totalImposto, setTotalImposto] = useState(0.00);
    const [totalFee, setTotalFee] = useState(0.00);
    const [totalTxacrt, setTotalTxacrt] = useState(0.00);
    const [totalTxDu, setTotalTxDu] = useState(0.00);
    const [totalLiquido, setTotalLiquido] = useState(0.00);

    useEffect(() => {
        setBilhetes(props.fatura.bilhetes);
        setTotalTarifa(formatNumberPosMount(props.fatura.totalTarifaGeral));
        setTotalTaxa(formatNumberPosMount(props.fatura.totalTaxa));
        setTotalCartao(formatNumberPosMount(props.fatura.totalCartao));
        setTotalComissao(formatNumberPosMount(props.fatura.totalComissao));
        setTotalIncentivo(formatNumberPosMount(props.fatura.totalIncentivo));
        setTotalDesconto(formatNumberPosMount(props.fatura.totalDesconto));
        setTotalImposto(formatNumberPosMount(props.fatura.totalImposto));
        setTotalFee(formatNumberPosMount(props.fatura.totalFee));
        setTotalTxacrt(formatNumberPosMount(props.fatura.totalTxacrt));
        setTotalTxDu(formatNumberPosMount(props.fatura.totalTxDu));
        setTotalLiquido(formatNumberPosMount(props.fatura.totalLiquido));
    }, [bilhetes, totalTarifa, totalTaxa, totalCartao, totalComissao, totalIncentivo, totalDesconto, 
        totalImposto, totalFee, totalTxacrt, totalTxDu, totalLiquido, 
        props.fatura.bilhetes, props.fatura.totalTarifaGeral, props.fatura.totalTaxa, 
        props.fatura.totalCartao, props.fatura.totalComissao, props.fatura.totalIncentivo,
        props.fatura.totalDesconto, props.fatura.totalImposto, props.fatura.totalFee,
        props.fatura.totalTxacrt, props.fatura.totalTxDu, props.fatura.totalLiquido]);

    
    const formatNumberPosMount = (number) => {
        const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
        const formatNumber = new Intl.NumberFormat('pt-BR', options)
        return number !== "undefined" ? formatNumber.format(number) : 0.00;
    }

    const header = (
        <HeaderTable>
            <IconHeader/><HeaderTitulo>BILHETES</HeaderTitulo>
        </HeaderTable>
    );

    let footerGroup =
        <ColumnGroup>
            <Row>
                <Column style={{ width: '400px', color: "white", backgroundColor: "#4F001E" }} footer="" />
                <Column style={{ width: '250px', color: "white", backgroundColor: "#4F001E" }} footer="" />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#4F001E" }} footer="" />
                <Column style={{ width: '100px', color: "white", backgroundColor: "#4F001E" }} footer="" />
                <Column style={{ width: '100px', color: "white", backgroundColor: "#4F001E", textAlign:"right" }} colSpan={2}  footer="Total Geral" />
                <Column style={{ width: '100px', color: "white", backgroundColor: "#4F001E", textAlign:"right" }} footer={totalTarifa} />
                <Column style={{ width: '100px', color: "white", backgroundColor: "#4F001E", textAlign: 'right' }} footer={totalTaxa} />
                <Column style={{ width: '100px', color: "white", backgroundColor: "#4F001E", textAlign: 'right' }} footer={totalCartao} />
                <Column style={{ width: '100px', color: "white", backgroundColor: "#4F001E", textAlign: 'right' }} footer={totalComissao} />
                <Column style={{ width: '100px', color: "white", backgroundColor: "#4F001E", textAlign: 'right' }} footer={totalIncentivo} />
                <Column style={{ width: '100px', color: "white", backgroundColor: "#4F001E", textAlign: 'right' }} footer={totalDesconto} />
                <Column style={{ width: '100px', color: "white", backgroundColor: "#4F001E", textAlign: 'right' }} footer={totalImposto} />
                <Column style={{ width: '100px', color: "white", backgroundColor: "#4F001E", textAlign: 'right' }} footer={totalFee} />
                <Column style={{ width: '100px', color: "white", backgroundColor: "#4F001E", textAlign: 'right' }} footer={totalTxacrt} />
                <Column style={{ width: '100px', color: "white", backgroundColor: "#4F001E", textAlign: 'right' }} footer={totalLiquido} />
                <Column style={{ width: '100px', color: "white", backgroundColor: "#4F001E", textAlign: 'right' }} footer={totalTxDu} />
            </Row>
        </ColumnGroup>;

    const reactFooter = (totalizadores) => {
        return (
            <React.Fragment>
                <td style={{ width: '400px' }}></td>
                <td style={{ width: '250px' }}></td>
                <td style={{ width: '150px' }}></td>
                <td style={{ width: '100px' }}></td>
                <td style={{ width: '100px' }}></td>
                <td style={{ width: '100px' }}><FooterGroup>Total Cia:</FooterGroup></td>
                <td style={{ width: '100px' }}><FooterGroup>{totalizadores.tarifa}</FooterGroup></td>
                <td style={{ width: '100px' }}><FooterGroup>{totalizadores.taxa}</FooterGroup></td>
                <td style={{ width: '100px' }}><FooterGroup>{totalizadores.cartao}</FooterGroup></td>
                <td style={{ width: '100px' }}><FooterGroup>{totalizadores.comissao}</FooterGroup></td>
                <td style={{ width: '100px' }}><FooterGroup>{totalizadores.incentivo}</FooterGroup></td>
                <td style={{ width: '100px' }}><FooterGroup>{totalizadores.desconto}</FooterGroup></td>
                <td style={{ width: '100px' }}><FooterGroup>{totalizadores.imposto}</FooterGroup></td>
                <td style={{ width: '100px' }}><FooterGroup>{totalizadores.fee}</FooterGroup></td>
                <td style={{ width: '100px' }}><FooterGroup>{totalizadores.txadmcrt}</FooterGroup></td>
                <td style={{ width: '100px' }}><FooterGroup>{totalizadores.liquido}</FooterGroup></td>
                <td style={{ width: '100px' }}><FooterGroup>{totalizadores.txdu}</FooterGroup></td>
            </React.Fragment>
        );
    }

    const footerTemplate = (data) => {
        let totalizadores = calculateTicketTotal(data.grupo);

        return reactFooter(totalizadores);
    }

    const headerTemplate = (data) => {
        return (
            <React.Fragment>
                <SpanTitle >Companhia:&nbsp;</SpanTitle><SpanData>{data.codcia}</SpanData>
                <SpanTitle>Emissão:&nbsp;</SpanTitle><SpanData>{data.fpagto}</SpanData>
                <SpanTitle>Tipo:&nbsp;</SpanTitle><SpanData>{data.tipoFormatado}</SpanData>
            </React.Fragment>
        );
    }

    const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    const formatNumber = new Intl.NumberFormat('pt-BR', options)
   
    const tarifaBodyTemplate = (rowData) => {   
        let value = rowData.tarifa;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const taxaBodyTemplate = (rowData) => {
        let value = rowData.taxa;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const cartaoBodyTemplate = (rowData) => {
        let value = rowData.cartaoVl;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const comissaoBodyTemplate = (rowData) => {
        let value = rowData.comissao;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const incentivoBodyTemplate = (rowData) => {
        let value = rowData.incentivo;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const descontoBodyTemplate = (rowData) => {
        let value = rowData.desconto;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const impostoBodyTemplate = (rowData) => {
        let value = rowData.imposto;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const feeBodyTemplate = (rowData) => {
        let value = rowData.fee;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const txacrtBodyTemplate = (rowData) => {
        let value = rowData.txadmcrt;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const liquidoBodyTemplate = (rowData) => {
        let value = rowData.liquido;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const txduBodyTemplate = (rowData) => {
        let value = rowData.txdu;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const dataEmissaoBodyTemplate = (rowData) => {
        return moment(rowData.emissao).format('DD/MM/YYYY')
    }
    const dataEmbarqueBodyTemplate = (rowData) => {
        return moment(rowData.dataFatura).format('DD/MM/YYYY')
    }


    const calculateTicketTotal = (grupo) => {
        let totalTarifa = 0;
        let totalTaxa = 0;
        let totalCartao = 0;
        let totalComissao = 0;
        let totalIncentivo = 0;
        let totalDesconto = 0;
        let totalImposto = 0;
        let totalFee = 0;
        let totalTxacrt = 0;
        let totalTxDu = 0;
        let totalLiquido = 0;

        if (bilhetes) {
            for (let bilhete of bilhetes) {
                if (grupo === undefined || bilhete.grupo === grupo) {
                    totalTarifa += bilhete.tarifa;
                    totalTaxa += bilhete.taxa;
                    totalCartao += bilhete.cartaoVl;
                    totalComissao += bilhete.comissao;
                    totalIncentivo += bilhete.incentivo;
                    totalDesconto += bilhete.desconto;
                    totalImposto += bilhete.imposto;
                    totalFee += bilhete.fee;
                    totalTxacrt += bilhete.txadmcrt;
                    totalLiquido += bilhete.liquido;
                    totalTxDu += bilhete.txdu;
                }
            }
        }
        return {
            
            tarifa: formatNumber.format(totalTarifa),
            taxa: formatNumber.format(totalTaxa),
            cartao: formatNumber.format(totalCartao),
            comissao: formatNumber.format(totalComissao),
            incentivo: formatNumber.format(totalIncentivo),
            desconto: formatNumber.format(totalDesconto),
            imposto: formatNumber.format(totalImposto),
            fee: formatNumber.format(totalFee),
            txadmcrt: formatNumber.format(totalTxacrt),
            liquido: formatNumber.format(totalLiquido),
            txdu: formatNumber.format(totalTxDu)
        };
    }

    return (
        <DataTable footerColumnGroup={footerGroup} header={header}  
            size="small" rowGroupFooterTemplate={footerTemplate} stripedRows value={bilhetes}
            responsiveLayout="scroll" rowGroupMode="subheader" groupRowsBy="grupo"
            sortMode="single" sortOrder={1} rowGroupHeaderTemplate={headerTemplate} scrollDirection="both" >
            <Column bodyStyle={{ width: '400px' }} headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '400px', fontSize:"12px"}} field="passageiro" header="PASSAGEIRO"></Column>
            <Column bodyStyle={{ width: '250px', textAlign: 'center'  }} headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '250px', fontSize:"12px"  }} field="rota" header="ROTA"></Column>
            <Column bodyStyle={{ width: '150px', textAlign: 'center' }} headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '150px', fontSize:"12px"  }} field="tktIsolado" header="Nº BILHETE"></Column>
            <Column bodyStyle={{ width: '100px', textAlign: 'center' }} headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '100px', fontSize:"12px"  }} field="locIsolado" header="LOC"></Column>
            <Column bodyStyle={{ width: '100px', textAlign: 'center' }} headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '100px', fontSize:"12px"  }} field={dataEmissaoBodyTemplate} header="EMISSÃO"></Column>
            <Column bodyStyle={{ width: '100px', textAlign: 'center' }} headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '100px', fontSize:"12px"  }} field={dataEmbarqueBodyTemplate} header="EMBARQUE"></Column>
            <Column bodyStyle={{ textAlign: 'right', width: '100px' }} style={{textAlign: 'right'}}  headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '100px', fontSize:"12px"  }} field={tarifaBodyTemplate} header="TARIFA"></Column>
            <Column bodyStyle={{ textAlign: 'right', width: '100px' }} headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '100px', fontSize:"12px"  }} field={taxaBodyTemplate} header="TAXA"></Column>
            <Column bodyStyle={{ textAlign: 'right', width: '100px' }} headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '100px', fontSize:"12px"  }} field={cartaoBodyTemplate} header="CART./RAV"></Column>
            <Column bodyStyle={{ textAlign: 'right', width: '100px' }} headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '100px', fontSize:"12px"  }} field={comissaoBodyTemplate} header="COMISSÃO"></Column>
            <Column bodyStyle={{ textAlign: 'right', width: '100px' }} headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '100px', fontSize:"12px"  }} field={incentivoBodyTemplate} header="INCENTIVO"></Column>
            <Column bodyStyle={{ textAlign: 'right', width: '100px' }} headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '100px', fontSize:"12px"  }} field={descontoBodyTemplate} header="DESCONTO"></Column>
            <Column bodyStyle={{ textAlign: 'right', width: '100px' }} headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '100px', fontSize:"12px"  }} field={impostoBodyTemplate} header="IMPOSTO"></Column>
            <Column bodyStyle={{ textAlign: 'right', width: '100px' }} headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '100px', fontSize:"12px"  }} field={feeBodyTemplate} header="FEE"></Column>
            <Column bodyStyle={{ textAlign: 'right', width: '100px' }} headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '100px', fontSize:"12px"  }} field={txacrtBodyTemplate} header="CRT"></Column>
            <Column bodyStyle={{ textAlign: 'right', width: '100px' }} headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '100px', fontSize:"12px"  }} field={liquidoBodyTemplate} header="LÍQUIDO"></Column>
            <Column bodyStyle={{ textAlign: 'right', width: '100px' }} headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '100px', fontSize:"12px"  }} field={txduBodyTemplate} header="TX DU"></Column>
        </DataTable>
    );

}

export default TabelaBilhetes;
