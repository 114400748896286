import React, { useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import moment from 'moment'
import Fab from '@mui/material/Fab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import ReembolsoService from '../../api/services/ReembolsoService';
import { useDropzone } from 'react-dropzone';
import { getCodEmpUser } from '../../api/services/AuthService';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Backdrop from '../../backdrop/BackDrop';
import { TextField } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../theme/Theme';
import UploadFileService from '../../api/services/UploadFileService';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import styled from "styled-components";
import { DeleteForever } from '@mui/icons-material';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import { motion } from "framer-motion";
import CheckIcon from '@mui/icons-material/Check';


const AreaDropzone = styled.section`
    margin-top:10px !important;
    margin-bottom:10px !important;
`

const Aside = styled.aside`
    margin-bottom:10px !important;
`

const Drop = styled.div(props => ({
    border: '1px dashed black',
    height: '50px',
    width: '500px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#E6E6E6'
}));

const LinkSolicitarReembolso = styled(Link)`
    float: right;
`

const LinkDeleteIcon = styled(Link)`
    color:#4F001E;
    &:hover {
        color: red;
    }
  `
const LinkDeleteTodosIcon = styled(Link)`
    color:#4F001E;
    margin-botton:15px;
    &:hover {
        color: red;
    }
  `

const Tabela = styled.div`
   height: 300px;
   overflow: auto;
   width: 100%;
   padding-bottom:10px;
   padding-top:20px;
`

const LayoutSolicitacao = styled.div(props => ({
    display: props.display,
    backgroundColor: 'bold',
    width: '95%',
    height: 'auto',
    minHeight: '680px',
    top: '80px',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    marginTop: '100px'
}));

const Titulo = styled(Typography)`
    color:#4F001E;
    font-weight:bold;
    width:100%;
    margin-botton:30px;
`

const MsgTitulo = styled(Typography)`
    color:#4F001E;
    font-weight:bold;
    width:45%;
    float: left;
`
const Header = styled(Box)`   
    margin-top:10px;
    width:100%;
    height:50px;
`

const SolicitarReembolso = (props) => {

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const [open, setOpen] = useState(false);
    const [backdrop, setBackdrop] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [obs, setObs] = useState("");
    const [checkbox1, setCheckbox1] = useState(false);
    const [checkbox2, setCheckbox2] = useState(false);
    const [myFiles, setMyFiles] = useState([]);
    const [ids, setIds] = useState([]);
    const [openSnack, setOpenSnack] = useState(false);

    const handleClickSnack = () => {
        setOpenSnack(true);
    };

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };

    const onDrop = useCallback(acceptedFiles => {
        setMyFiles([...myFiles, ...acceptedFiles])
        acceptedFiles.forEach((file) => {
            myFiles.push(file);
        })
    }, [myFiles])

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        maxSize: 12048576
    })

    const removeFile = file => () => {
        const newFiles = [...myFiles]
        newFiles.splice(newFiles.indexOf(file), 1)
        setMyFiles(newFiles)
    }

    const removeAll = () => {
        setMyFiles([])
    }

    const reembolsoService = new ReembolsoService();
    const upload = new UploadFileService();

    let navigate = useNavigate();

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    const handleToggle = () => {
        setOpen(!open);
    };

    const handleClickModalOpen = (user) => {
        setOpenConfirm(true);
    };

    const showBilhetes = () => {
        setOpenConfirm(false);
        handleToggle();
        props.setViewSearchBilhetes('block');
        props.setViewSearchSolicitacao('none');

        handleClose();
    }

    const saveFiles = (solicitacoes, user) => {
        solicitacoes.forEach((sol) => {
            ids.push(sol.idseq);
        })
        sendFiles(user);
    }

    function sendFiles(user) {
        const data = new FormData();
        for (let i = 0; i < myFiles.length; i++) {
            data.append('files', myFiles[i])
        }
        data.append('idSolicitacoes', ids);
        data.append('codemp', user);
        upload.salvar(data)
            .then(response => {
                reembolsoService.enviarEmails({
                    solicitacoes: ids,
                    codemp: user
                });
            }).catch(erro => {
                console.log(erro);
            }).finally(() => {

            });
    }

    const salvarSolicitacao = () => {
        handleToggle();
        setBackdrop(true);
        let user = getCodEmpUser();
        if (user) {
            if (!checkbox1 || !checkbox2) {
                handleClickSnack();
                handleClose();
                setBackdrop(false);
            }else {
                reembolsoService.salvar({
                    bilhetes: props.selecionados,
                    obs: obs,
                    checkbox1: checkbox1,
                    checkbox2: checkbox2,
                    codemp: user,
                }).then(response => {
                    if (myFiles.length > 0) {
                        saveFiles(response.data, user);
                    } else {
                        response.data.forEach((sol) => {
                            ids.push(sol.idseq);
                        })
                        reembolsoService.enviarEmails({
                            solicitacoes: ids,
                            codemp: user
                        });
                    }
                    navigate('/reembolso', { replace: true, state: { solicitacoes: response.data, snack: true } })
                }).catch(erro => {
                    console.log("erro");
                }).finally(() => {
                    handleClose();
                    setBackdrop(false);
                    setIds([]);
                })
            }
        } else {
            navigate('/login', { state: { expired: true } })
        }
    };

    const columns = [
        { field: 'numtkt', headerName: 'Ticket', width: 150 },
        {
            field: 'dataemi', headerName: 'Dt. Emissão', width: 170,
            renderCell: (params) => {
                return moment(params.value).format('DD/MM/YYYY');
            },
        },
        { field: 'loc', headerName: 'Loc', width: 150 },
        {
            field: 'cartao', headerName: 'Cartão', width: 150, renderCell: (params) => {
                return params.value !== null && params.value === true ? 'Sim' : ' Não ';
            },
        },
        { field: 'pax', headerName: 'Passageiro', width: 200 },
        { field: 'stat', headerName: 'Tipo Pax', width: 140 },
        { field: 'codcia', headerName: 'Cia', width: 130 },
        { field: 'rota', headerName: 'Rota', width: 200 },
        {
            field: 'dataemb', headerName: 'Data Voo', width: 145,
            renderCell: (params) => {
                return params.value !== null && params.value !== '' ? moment(params.value).format('DD/MM/YYYY') : '-';
            },
        },
    ];

    return (
        <ThemeProvider theme={theme}>
            <motion.div
                className='reembolso'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
                <Backdrop onOpen={backdrop}></Backdrop>
                <LayoutSolicitacao className="container" display={props.viewSearchSolicitacao}>
                    <Header>
                        <Titulo>
                            <MsgTitulo variant="h5" gutterBottom component="div">SOLICITAÇÃO DE REEMBOLSO</MsgTitulo>
                            <LinkSolicitarReembolso to='/reembolso' title="Listar Reembolsos Solicitados">
                                <Fab sx={{ zIndex: 0, marginRight: 0 }} color="primary" aria-label="add" size="small">
                                    <ListAltIcon />
                                </Fab>
                            </LinkSolicitarReembolso>
                            <Fab onClick={handleClickModalOpen} sx={{ zIndex: 0, marginRight: 1, float: "right" }} color="primary" aria-label="add" size="small">
                                <ArrowBackIcon />
                            </Fab>
                        </Titulo>
                    </Header>
                    <Card sx={{ minWidth: 800, backgroundColor: "#F5F5F5", flexGrow: 1, marginTop: 1, marginBottom: 2 }}>
                        <CardHeader
                            sx={{ height: '20px', paddingLeft: 2, paddingTop: 4 }}
                            titleTypographyProps={{ fontSize: "24px", color: "#4F001E" }}
                            title="Bilhetes Selecionados"
                            avatar={
                                <Avatar variant="square" sx={{ backgroundColor: "#4F001E", width: "30px", height: "30px" }} aria-label="recipe">
                                    <AirplaneTicketIcon />
                                </Avatar>
                            }
                        >
                        </CardHeader>
                        <CardContent>
                            <Tabela>
                                <DataGrid
                                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={props.selecionados}
                                    getRowId={(row) => row.numtkt}
                                    columns={columns}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    density="compact"
                                />
                            </Tabela>
                        </CardContent>
                    </Card>
                    <Card sx={{ minWidth: 800, backgroundColor: "#F5F5F5", marginTop: 1, marginBottom: 7 }}>
                        <CardHeader
                            sx={{ height: '20px', paddingLeft: 2, paddingTop: 4, marginBottom: 1 }}
                            titleTypographyProps={{ fontSize: "24px", color: "#4F001E" }}
                            title="Finalizar Solicitação"
                            avatar={
                                <Avatar variant="square" sx={{ backgroundColor: "#4F001E", width: "30px", height: "30px" }} aria-label="recipe">
                                    <AssignmentTurnedInIcon />
                                </Avatar>
                            }
                        >
                        </CardHeader>
                        <CardContent>
                            <Box component="form">
                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                    <Grid item xs={6} sm={6} md={6}>
                                        <TextField
                                            id="outlined-multiline-static"
                                            label="Observação (Máximo 256 caracteres)"
                                            multiline
                                            sx={{ width: 500, backgroundColor: "#FFFF" }}
                                            rows={6}
                                            defaultValue=""
                                            value={obs}
                                            inputProps={{
                                                maxLength: 255,
                                            }}

                                            onChange={(event) => {
                                                setObs(event.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} >
                                        <FormControlLabel sx={{ marginLeft: 2, textAlign: 'justify', width: "90%" }}
                                            control={
                                                <Checkbox
                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                    value={checkbox1}
                                                    onChange={(event) => {
                                                        setCheckbox1(event.target.checked);
                                                    }}
                                                />
                                            }
                                            label={ReembolsoService.firstCheck}
                                        />
                                        <FormControlLabel sx={{ marginLeft: 2, marginTop: 2, textAlign: 'justify', width: "90%" }}
                                            control={
                                                <Checkbox
                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                    value={checkbox2}
                                                    onChange={(event) => {
                                                        setCheckbox2(event.target.checked);
                                                    }}
                                                />
                                            }
                                            label={ReembolsoService.secondCheck}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6}>
                                        <Button startIcon={<CheckIcon />}  sx={{ marginTop: 2.2, marginLeft: 3 }} onClick={salvarSolicitacao} variant="contained">Confirmar</Button>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6}>
                                        <AreaDropzone>
                                            <Drop {...getRootProps({ className: 'dropzone' })}>
                                                <input {...getInputProps()} />
                                                <Typography sx={{ fontWeight: 'bold', boder: '1 solid', color: '#4F001E', margin: 'auto' }} variant="body2"
                                                    gutterBottom component="div">
                                                    CLIQUE OU ARRASTE AQUI OS ARQUIVOS<br></br> Max 12 mb de tamanho!</Typography>
                                            </Drop>
                                            <Aside>
                                                {myFiles.length > 0 &&
                                                    <Typography sx={{ paddingTop: 2, fontWeight: 'bold' }} variant="body1" gutterBottom component="div">
                                                        Arquivos Selecionados:
                                                    </Typography>}
                                                {myFiles.map((file, index) => (
                                                    <ListItem button key={index} sx={{ width: '87%' }}>
                                                        <ListItemText primary={`${file.path} - ${file.size} bytes`} />
                                                        <LinkDeleteIcon
                                                            to="#"
                                                            onClick={removeFile(file)}>
                                                            <DeleteIcon />
                                                        </LinkDeleteIcon>
                                                    </ListItem>
                                                ))}
                                                {myFiles.length > 0 && <LinkDeleteTodosIcon
                                                    to="#"
                                                    onClick={removeAll}>
                                                    <DeleteForever />
                                                </LinkDeleteTodosIcon>}
                                            </Aside>
                                        </AreaDropzone>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                    <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
                        <Alert onClose={handleCloseSnack} severity="error" sx={{ width: '100%' }}>
                            Por favor, para realizar a solicitação você deve estar de acordo com as termos declarados na página!
                        </Alert>
                    </Snackbar>

                    <Dialog
                        open={openConfirm}
                        onClose={handleCloseConfirm}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Cancelamento de Processo de Solicitação"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Deseja realmente retornar e cancelar esta solicitação em andamento?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseConfirm}>Não</Button>
                            <Button onClick={showBilhetes} autoFocus>
                                Sim
                            </Button>
                        </DialogActions>
                    </Dialog>
                </LayoutSolicitacao>
            </motion.div>
        </ThemeProvider>
    )
}
export default SolicitarReembolso;