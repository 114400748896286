import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import moment from 'moment'
import styled from "styled-components";
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle'


const SpanTitle = styled.span`
    font-size:16px;
    color:#4F001E;
    font-weight:bold;
`
const SpanData = styled.span`
    margin-right:50px;
    font-size:16px;
    color:#4F001E;
    font-weight:bold;
    display:inline-block;
    width:300px;
`
const SpanData2 = styled.span`
    margin-right:50px;
    font-size:16px;
    color:#4F001E;
    font-weight:bold;
    display:inline-block;
    width:120px;
`
const FooterGroup = styled.p`
    text-align:right;
    font-size:15px;
    color:#4F001E;
    font-weight:bold;
`
const HeaderTable = styled.div`
    text-align:center;
    color:#4F001E;
    font-size:24px;
`

const IconHeader = styled(AirportShuttleIcon)`
    vertical-align:text-bottom;
    margin-right:10px;
    width: 30px !important;
    height: 30px !important;
`

const HeaderTitulo = styled.span`
    margin-bottom:10px;
`

const TabelaOnibus = (props) => {
    const [onibus, setOnibus] = useState([]);
    const [totalDiaria, setTotalDiaria] = useState(0.00);
    const [totalTotalizadorDiaria, setTotalTotalizadorDiaria] = useState(0.00);
    const [totalTaxa, setTotalTaxa] = useState(0.00);
    const [totalCartao, setTotalCartao] = useState(0.00);
    const [totalComissao, setTotalComissao] = useState(0.00);
    const [totalIncentivo, setTotalIncentivo] = useState(0.00);
    const [totalDesconto, setTotalDesconto] = useState(0.00);
    const [totalFee, setTotalFee] = useState(0.00);
    const [totalLiquido, setTotalLiquido] = useState(0.00);
    const [totalExtras, setTotalExtras] = useState(0.00);

    useEffect(() => {
        setOnibus(props.fatura.onibus);
        setTotalDiaria(formatNumberPosMount(props.fatura.totalDiariaOnibus));
        setTotalTaxa(formatNumberPosMount(props.fatura.totalTaxaOnibus));
        setTotalCartao(formatNumberPosMount(props.fatura.totalCartaoOnibus));
        setTotalComissao(formatNumberPosMount(props.fatura.totalComissaoOnibus));
        setTotalIncentivo(formatNumberPosMount(props.fatura.totalIncentivoOnibus));
        setTotalDesconto(formatNumberPosMount(props.fatura.totalDescontoOnibus));
        setTotalFee(formatNumberPosMount(props.fatura.totalFeeOnibus));
        setTotalLiquido(formatNumberPosMount(props.fatura.totalLiquidoOnibus));
        setTotalTotalizadorDiaria(formatNumberPosMount(props.fatura.totalTotalizadorDiariaOnibus));
        setTotalExtras(formatNumberPosMount(props.fatura.totalExtrasOnibus));
    }, [onibus, totalTaxa, totalCartao, totalComissao, totalIncentivo, totalDesconto,
        totalFee, totalLiquido, props.fatura.Onibuss, props.fatura.totalTarifaGeral,
        props.fatura.totalTaxa, props.fatura.totalCartao, props.fatura.totalComissao,
        props.fatura.totalIncentivo, props.fatura.totalDesconto, props.fatura.totalImposto,
        props.fatura.totalFee, props.fatura.totalTxacrt, props.fatura.totalTxDu, props.fatura.totalLiquido,
        props.fatura.TabelaHoteis, props.fatura.hoteis, props.fatura.totalDiariaHotel,
        props.fatura.totalTaxaHotel, props.fatura.totalCartaoHotel, props.fatura.totalComissaoHotel,
        props.fatura.totalIncentivoHotel, props.fatura.totalDescontoHotel, props.fatura.totalFeeHotel,
        props.fatura.totalLiquidoHotel, props.fatura.totalTotalizadorDiariaHotel, props.fatura.totalExtrasHotel,
        props.fatura.totalDiariaOnibus, props.fatura.totalTaxaOnibus, props.fatura.totalCartaoOnibus,
        props.fatura.totalComissaoOnibus, props.fatura.totalIncentivoOnibus, props.fatura.totalDescontoOnibus,
        props.fatura.totalFeeOnibus, props.fatura.totalLiquidoOnibus, props.fatura.totalTotalizadorDiariaOnibus,
        props.fatura.totalExtrasOnibus, props.fatura.onibus]);


    const formatNumberPosMount = (number) => {
        const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
        const formatNumber = new Intl.NumberFormat('pt-BR', options)
        return number !== "undefined" ? formatNumber.format(number) : 0.00;
    }

    const header = (
        <HeaderTable>
            <IconHeader /><HeaderTitulo>RODOVIÁRIO</HeaderTitulo>
        </HeaderTable>
    );

    let footerGroup =
        <ColumnGroup>
            <Row>
                <Column style={{ width: '400px', color: "white", backgroundColor: "#4F001E" }} footer="" />
                <Column style={{ width: '100px', color: "white", backgroundColor: "#4F001E" }} footer="" />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#4F001E" }} footer="" />
                <Column style={{ width: '300px', color: "white", backgroundColor: "#4F001E" }} footer="" />
                <Column style={{ width: '100px', color: "white", backgroundColor: "#4F001E" }} footer="" />
                <Column style={{ width: '100px', color: "white", backgroundColor: "#4F001E" }} footer="Total Geral:" />
                <Column style={{ width: '100px', color: "white", backgroundColor: "#4F001E" }} footer={totalDiaria} />
                <Column style={{ width: '100px', color: "white", backgroundColor: "#4F001E" }} footer={totalTotalizadorDiaria} />
                <Column style={{ width: '100px', color: "white", backgroundColor: "#4F001E" }} footer={totalTaxa} />
                <Column style={{ width: '100px', color: "white", backgroundColor: "#4F001E" }} footer={totalExtras} />
                <Column style={{ width: '100px', color: "white", backgroundColor: "#4F001E" }} footer={totalCartao} />
                <Column style={{ width: '100px', color: "white", backgroundColor: "#4F001E" }} footer={totalIncentivo} />
                <Column style={{ width: '100px', color: "white", backgroundColor: "#4F001E" }} footer={totalComissao} />
                <Column style={{ width: '100px', color: "white", backgroundColor: "#4F001E" }} footer={totalDesconto} />
                <Column style={{ width: '100px', color: "white", backgroundColor: "#4F001E" }} footer={totalFee} />
                <Column style={{ width: '100px', color: "white", backgroundColor: "#4F001E" }} footer={totalLiquido} />
            </Row>
        </ColumnGroup>;

    const reactFooter = (totalizadores) => {
        return (
            <React.Fragment>
                <td style={{ width: '400px' }}></td>
                <td style={{ width: '100px' }}></td>
                <td style={{ width: '150px' }}></td>
                <td style={{ width: '300px' }}></td>
                <td style={{ width: '100px' }}></td>
                <td style={{ width: '100px' }}><FooterGroup>Total:</FooterGroup></td>
                <td style={{ width: '100px' }}><FooterGroup>{totalizadores.diaria}</FooterGroup></td>
                <td style={{ width: '100px' }}><FooterGroup>{totalizadores.totalDiaria}</FooterGroup></td>
                <td style={{ width: '100px' }}><FooterGroup>{totalizadores.totalTaxa}</FooterGroup></td>
                <td style={{ width: '100px' }}><FooterGroup>{totalizadores.extras}</FooterGroup></td>
                <td style={{ width: '100px' }}><FooterGroup>{totalizadores.cartao}</FooterGroup></td>
                <td style={{ width: '100px' }}><FooterGroup>{totalizadores.incentivo}</FooterGroup></td>
                <td style={{ width: '100px' }}><FooterGroup>{totalizadores.comissao}</FooterGroup></td>
                <td style={{ width: '100px' }}><FooterGroup>{totalizadores.desconto}</FooterGroup></td>
                <td style={{ width: '100px' }}><FooterGroup>{totalizadores.fee}</FooterGroup></td>
                <td style={{ width: '100px' }}><FooterGroup>{totalizadores.liquido}</FooterGroup></td>
            </React.Fragment>
        );
    }

    const footerTemplate = (data) => {
        let totalizadores = countHotelTotal(data.grupo);

        return reactFooter(totalizadores);
    }

    const headerTemplate = (data) => {
        return (
            <React.Fragment>
                <SpanTitle>COMPANHIA:&nbsp;</SpanTitle><SpanData>{data.nomefnsv}</SpanData>
                <SpanTitle>EMISSÃO:&nbsp;</SpanTitle><SpanData2>{data.fpagtoFormatado}</SpanData2>
                <SpanTitle>TIPO:&nbsp;</SpanTitle><SpanData2>{data.tipoFormatado}</SpanData2>
            </React.Fragment>
        );
    }

    const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    const formatNumber = new Intl.NumberFormat('pt-BR', options)

    const extrasBodyTemplate = (rowData) => {
        let value = rowData.valextcl;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const cartaoBodyTemplate = (rowData) => {
        let value = rowData.valcrtcl;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const comissaoBodyTemplate = (rowData) => {
        let value = rowData.valrpcl;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const incentivoBodyTemplate = (rowData) => {
        let value = rowData.valcmcl;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const descontoBodyTemplate = (rowData) => {
        let value = rowData.valdesc;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const liquidoBodyTemplate = (rowData) => {
        let value = rowData.valarec;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const feeBodyTemplate = (rowData) => {
        let value = rowData.txadcli;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const taxaBodyTemplate = (rowData) => {
        let value = rowData.taxa;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const totalDiariasBodyTemplate = (rowData) => {
        let value = rowData.tarifa;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const diariaBodyTemplate = (rowData) => {
        let value = rowData.tarifpro;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const paxBodyTemplate = (rowData) => {

        let value = rowData.pax;
        var passageiros = value.split("%");
        return (
            <div className="flex flex-wrap gap-2">
                {passageiros.map(pax => <><span>{pax}</span><br></br></>)}
            </div>
        );
    }

    const dataEmissaoBodyTemplate = (rowData) => {
        return moment(rowData.emissao).format('DD/MM/YYYY')
    }

    const checkinBodyTemplate = (rowData) => {
        return moment(rowData.datain).format('DD/MM/YYYY')
    }

    const countHotelTotal = (grupo) => {
        let totalDiaria = 0;
        let totalTotalizadorDiaria = 0;
        let totalTaxa = 0;
        let totalExtras = 0;
        let totalCartao = 0;
        let totalIncentivo = 0;
        let totalComissao = 0;
        let totalDesconto = 0;
        let totalFee = 0;
        let totalLiquido = 0;

        if (onibus) {
            for (let Onibus of onibus) {
                if (grupo === undefined || Onibus.grupo === grupo) {
                    totalDiaria += Onibus.tarifpro;
                    totalTotalizadorDiaria += Onibus.tarifa;
                    totalTaxa += Onibus.taxa;
                    totalCartao += Onibus.valcrtcl;
                    totalExtras += Onibus.valextcl;
                    totalIncentivo += Onibus.valcmcl;
                    totalComissao += Onibus.valrpcl;
                    totalDesconto += Onibus.valdesc;
                    totalFee += Onibus.txadcli;
                    totalLiquido += Onibus.valarec;
                }
            }
        }
        return {
            diaria: formatNumber.format(totalDiaria),
            totalDiaria: formatNumber.format(totalTotalizadorDiaria),
            totalTaxa: formatNumber.format(totalTaxa),
            cartao: formatNumber.format(totalCartao),
            extras: formatNumber.format(totalExtras),
            incentivo: formatNumber.format(totalIncentivo),
            comissao: formatNumber.format(totalComissao),
            desconto: formatNumber.format(totalDesconto),
            fee: formatNumber.format(totalFee),
            liquido: formatNumber.format(totalLiquido),
        };
    }

    return (
        <DataTable footerColumnGroup={footerGroup} header={header}
            size="small" rowGroupFooterTemplate={footerTemplate} stripedRows value={onibus}
            responsiveLayout="scroll" rowGroupMode="subheader" groupRowsBy="grupo"
            sortMode="single" sortOrder={1} rowGroupHeaderTemplate={headerTemplate} scrollable scrollHeight="500px" scrollDirection="both" >
            <Column bodyStyle={{ width: '400px' }} headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '400px', fontSize:"12px" }} field={paxBodyTemplate} header="NOME (S)"></Column>
            <Column bodyStyle={{ width: '100px' }} headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '100px', fontSize:"12px" }} field={dataEmissaoBodyTemplate} header="RESERVA"></Column>
            <Column bodyStyle={{ width: '150px' }} headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '150px', fontSize:"12px" }} field="numresv" header="IDENTIFICADOR"></Column>
            <Column bodyStyle={{ width: '300px' }} headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '300px', fontSize:"12px" }} field="rota" header="ROTA"></Column>
            <Column bodyStyle={{ width: '100px' }} headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '100px', fontSize:"12px" }} field={checkinBodyTemplate} header="EMBARQUE"></Column>
            <Column bodyStyle={{ width: '100px' }} headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '100px', fontSize:"12px" }} field="quanpax" header="Nº PAX"></Column>
            <Column bodyStyle={{ width: '100px' }} headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '100px', fontSize:"12px" }} field={diariaBodyTemplate} header="DIÁRIA R$"></Column>
            <Column bodyStyle={{ width: '100px' }} headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '100px', fontSize:"12px" }} field={totalDiariasBodyTemplate} header="TOT. DIÁRIAS"></Column>
            <Column bodyStyle={{ width: '100px' }} headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '100px', fontSize:"12px" }} field={taxaBodyTemplate} header="TAXA"></Column>
            <Column bodyStyle={{ width: '100px' }} headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '100px', fontSize:"12px" }} field={extrasBodyTemplate} header="EXTRAS"></Column>
            <Column bodyStyle={{ width: '100px' }} headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '100px', fontSize:"12px" }} field={cartaoBodyTemplate} header="CARTÃO"></Column>
            <Column bodyStyle={{ width: '100px' }} headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '100px', fontSize:"12px" }} field={incentivoBodyTemplate} header="INCENTIVO"></Column>
            <Column bodyStyle={{ width: '100px' }} headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '100px', fontSize:"12px" }} field={comissaoBodyTemplate} header="COMISSÃO"></Column>
            <Column bodyStyle={{ width: '100px' }} headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '100px', fontSize:"12px" }} field={descontoBodyTemplate} header="DESCONTO"></Column>
            <Column bodyStyle={{ width: '100px' }} headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '100px', fontSize:"12px" }} field={feeBodyTemplate} header="FEE"></Column>
            <Column bodyStyle={{ width: '100px' }} headerStyle={{ backgroundColor: '#4F001E', color: 'white', width: '100px', fontSize:"12px" }} field={liquidoBodyTemplate} header="LÍQUIDO"></Column>
        </DataTable>
    );
}

export default TabelaOnibus;