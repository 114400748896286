import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import { TextField } from '@material-ui/core';
import Alert from '@mui/material/Alert';
import aviao from '../../../assets/images/aviao.jpeg';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../theme/Theme';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import logo from '../../../assets/images/logo.png';

import styled from "styled-components";

import LoginService from '../../api/services/LoginService';


const DivLogin = styled.div`
    position:fixed;
    z-index:0;
    width:100%;
    height:100%;
    background-attachment: fixed;
    background-size: cover;
    background-position: right top;
  }
`

const DivLoginBack = styled.div`
    background-image:url(${aviao});  
    position:absolute;
    z-index:-1;
    width:100%;
    height:100%;
    background-attachment: fixed;
    background-size: cover;
    background-position: right top;
    opacity:0.3;
  }
`

const CardLogin = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;  
    z-index:2;
    opacity:0.8;
`
const CardHeaderLogin = styled(CardHeader)`
    background-color:#4F001E;
    color:white;
    font-height:bold;
    width:600px;
`

const AlertFeedback = styled(Alert)`
    margin-bottom:5px;
    display: ${({ isvisible }) => isvisible === 'true' ? 'flex !important' : 'none !important'}

`

const DivLogo = styled.div`
    align-items: center;
    z-index: 2;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    position:relative;
    color:#F60F9E;
    margin-bottom:30px;
    font-family: "Lucida Sans" !important;
`


const DivContent = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

const ImagemLogo = styled.img`
    width:450px;
    height:149px;
`

export default function RecuperarSenha() {
    const [email, setEmail] = useState("");
    const [feedback, setFeedback] = useState(false);
    const [variant, setVariant] = useState("error");
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);

    useEffect(() => {
    }, [variant, message, feedback, email]);

    let navigate = useNavigate();

    const servico = new LoginService();

    const goLogin = () => {
        navigate('/login');
    }

    function validarEmail() {
        let retorno = true;
        let message = '';
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

        if (email === '') {
            message += "Preencha o email!\n";
            retorno = false;
        } else {
            if (!emailRegex.test(email)) {
                message += "Email Inválido!\n";
                retorno = false;
            }
        }
        if (!retorno) {
            setMessage(message);
            setFeedback(true);
            setVariant("warning");
        }
        return retorno;
    }

    const resetarSenha = () => {
        setOpen(true);
        setFeedback(false)
        if (validarEmail()) {
            servico.recuperarSenha({
                email: email,
            }).then(response => {
                setFeedback(true);
                setVariant("success");
                setMessage("Senha Resetada! Você receberá um email com a senha provisória!")
            }).catch(erro => {
                setFeedback(true);
                setVariant("error");
                switch (erro?.response?.status) {
                    case 401:
                        setMessage("Usuário não autorizado!")
                        break;
                    case 404:
                        setMessage("Email não encontrado!")
                        break;
                    default:
                        setMessage("Problema ao resetar a senha, entre em contato com o adm do sistema!")
                        break;
                }
            }).finally(() => {
                setOpen(false);
            })
        } else {
            setOpen(false);
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <DivLogin>
                <DivLoginBack></DivLoginBack>
                <DivContent>
                    <DivLogo>
                        <ImagemLogo src={logo} />
                    </DivLogo>
                    <CardLogin>
                        <Card variant="outlined">
                            <CardHeaderLogin title="RECUPERAÇÃO DE SENHA" />
                            <CardContent>
                                <TextField
                                    id="email"
                                    autoComplete="off"
                                    label="Email Cadastrado"
                                    type="text"
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    placeholder="Email"
                                    hiddenLabel
                                    value={email}
                                    onChange={(event) => {
                                        setEmail(event.target.value);
                                    }}
                                />
                                <Stack sx={{ width: '100%' }} spacing={2}>
                                    <AlertFeedback isvisible={feedback.toString()} variant="filled" severity={variant}>
                                        {message}
                                    </AlertFeedback>
                                </Stack>
                                <Grid container spacing={2} style={{ marginTop: 5, marginBotton: '1em' }}>
                                    <Grid item xs={6}>
                                        <Button onClick={resetarSenha} variant="contained" color="primary">
                                            Enviar
                                        </Button>
                                        <Button onClick={goLogin} sx={{ marginLeft: 5 }} type="button" variant="contained" color="primary">
                                            Logar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </CardLogin>
                </DivContent>
            </DivLogin>
        </ThemeProvider>
    );
}